// ** Logo
import logo from '@src/assets/images/logo/icon-only-logo.png'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner vh-100'>
      <img style={{width:50}} className='fallback-logo' src={logo} alt='logo' />
      <div className='loading'>
        {/*<div className='effect-1 effects'></div>*/}
        {/*<div className='effect-2 effects'></div>*/}
        {/*<div className='effect-3 effects'></div>*/}
      </div>
    </div>
  )
}

export default SpinnerComponent
