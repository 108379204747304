// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootReducer from '../reducers/rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'

const persistConfig = {
    key: 'root',
    storage
  }

const persistedReducer = persistReducer(persistConfig, rootReducer)

// ** init middleware
const middleware = [thunk, createDebounce()]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
const store = createStore(persistedReducer,  {}, composeEnhancers(applyMiddleware(...middleware)))

const persistor = persistStore(store)


export { store, persistor  }
